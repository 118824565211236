import ApiService from '@zola-helpers/client/dist/es/http/api';
import staticFlags from 'libs/universal/utils/staticFlags';

export const getNav = (): Promise<string> => {
  if (staticFlags.get('navSsr')) {
    return ApiService.get<string>('/website-nav/web-api/babyNav');
  }
  return ApiService.get<string>('/web-registry-api/v1/nav/get', {}, {}).then(
    (text: string) => text
  );
};
