import React, { useState, useEffect } from 'react';
import Head from 'next/head';

import Nav from 'components/Nav';
import ComingSoon from 'components/ComingSoon';
import { getNav } from 'libs/client/nav/api';

type CustomErrorProps = {
  statusCode: number;
};

const CustomErrorPage: React.FC<CustomErrorProps> = ({ statusCode = 404 }) => {
  const [navHTML, setNavHTML] = useState('');
  useEffect(() => {
    getNav()
      .then(html => setNavHTML(html))
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error loading nav on 404 page');
      });
  }, []);

  return (
    <>
      <Head>
        <title>{statusCode === 404 ? 'Page Not Found' : 'Error Occurred'} - Zola Baby</title>
      </Head>
      <Nav navHTML={navHTML} user={null} showPromo={false} />
      <ComingSoon />
    </>
  );
};

export default CustomErrorPage;
